@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';

/* CourierPrime-Bold */
@font-face {
    font-family: 'CourierPrime';
    src: url('./assets/fonts/CourierPrime/CourierPrime-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'CourierPrime';
    src: url('./assets/fonts/CourierPrime/CourierPrime-Bold.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'CourierPrime';
    src: url('./assets/fonts/CourierPrime/CourierPrime-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'CourierPrime';
    src: url('./assets/fonts/CourierPrime/CourierPrime-BoldItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

/* Inter */
@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-Light.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-SemiBold.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-Bold.ttf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-ExtraBold.ttf');
    font-weight: 700;
    font-style: normal;
}

/* OpenSans */
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-Light.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-Bold.ttf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

html {
    box-sizing: border-box;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: Inter, sans-serif;
    box-sizing: inherit;
}

*:before,
*:after {
    box-sizing: inherit;
}

/* Disable blue border around text inputs */
.bp4-input:focus {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
        inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2) !important;
}

.role-select {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.role-select .bp4-popover-target {
    width: 100%;
}

.role-select .bp4-overlay {
    display: block !important;
    position: relative;
    width: 100%;
}

.role-select .bp4-transition-container {
    width: 100%;
    transform: none !important;
}

.role-select .bp4-popover {
    width: 100%;
}

.bp4-popover.no-shadow {
    box-shadow: none !important;
}

.role-select .bp4-menu-item.bp4-active {
    background-color: rgb(14, 197, 155) !important;
}

.bp4-spinner .bp4-spinner-head {
    stroke: rgb(21, 206, 150) !important;
}

.speakerbox .bp4-popover-wrapper.select-control {
    display: block;
}

.speakerbox .bp4-popover-wrapper.select-control > .bp4-popover-target {
    width: 100%;
}

.speakerbox .bp4-popover-wrapper.select-control > .bp4-popover-target .bp4-button {
    width: 100%;
    justify-content: flex-start;
}

.speakerbox
    .bp4-popover-wrapper.select-control
    > .bp4-popover-target
    .bp4-button
    > .bp4-icon:last-of-type {
    margin-left: auto;
}

@keyframes shakeX {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-5px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(5px, 0, 0);
    }
}

.shakeX {
    animation: shakeX 700ms linear;
}

.bp4-overlay-backdrop.popup-overlay-backdrop {
    background-color: transparent;
}

.glossary-type-edit .bp4-popover-target {
    width: 100%;
}

.toaster-position {
    top: 60px !important;
}

.toaster-position .bp4-toast-message {
    padding: 0px;
}

.exhibit-tooltip-popover.bp4-tooltip .bp4-popover-content {
    background: rgb(250 252 252);
    color: #394b59;
    padding: 6px 8px;
}

.section-select-popover {
    min-width: 220px;
}

.clean-popover-styles {
    padding: unset;
    border-radius: unset;
    box-shadow: unset;
}
.clean-popover-styles > .bp4-popover-content {
    max-width: unset !important;
    padding: unset !important;
    border-radius: unset !important;
}
.clean-popover-styles > .bp4-popover-arrow:before {
    box-shadow: unset;
    background: white;
    z-index: 1;
}

.str-chat.str-chat-channel.messaging.light {
    height: 100%;
}

.str-chat_channel-chat-container {
    height: 100%;
}

.bp4-popover2 {
    box-shadow: none;
}
