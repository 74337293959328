.lds-ellipsis,
.lds-ellipsis div {
    box-sizing: border-box;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 52px;
    height: 52px;
}
.lds-ellipsis div {
    position: absolute;
    top: 21.66667px;
    width: 8.66667px;
    height: 8.66667px;
    border-radius: 50%;
    background: #28d8c5;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 5.2px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 5.2px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 20.8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 36.4px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(15.6px, 0);
    }
}
